@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	font-size: 16px;
	width: 100%;
	overflow-x: hidden;
}

body {
	background: $color-black;
	color: $color-white;
	font-family: $primary-font, sans-serif;
	line-height: 1.5;
	width: 100%;
	overflow-x: hidden;
}

a {
	color: #FFFFFFCC;
	border-bottom: solid 1px rgba(255,255,255,.2);
	text-decoration: none;
	transition: .3s ease-in-out all;
	@include hover-focus {
		color: #fff;
		border-color: #fff;
	}
}

.page-wrap{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-height: 100vh;
}

#header {

}

#main {
	padding-bottom: rems(80);
	text-align: center;
	.container{
		max-width: rems(950);
	}
	p{
		font-family: 'futura-pt', sans-serif;
		font-size: rems(22);
		letter-spacing: .02em;
		font-weight: 500;
		margin:.5em 0;
	}
	a{
		font-family: 'europa', sans-serif;
		font-size: rems(16);
	}
}

#footer {
	font-size: rems(8);
}